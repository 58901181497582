<template>
  <div class="form--group row">
    <label class="col-12 col-lg-3">Email Yang Dibolehkan</label>
    <div class="col-12 col-lg-6">
      <allowed-email-field
        v-for="(allowedEmail, index) in allowedEmails"
        :key="index"
        :index="index"
        :allowedEmail="allowedEmail"
        ref="allowedEmailField"
        :allowedEmailLength="allowedEmails.length"
        placeholder="Email Yang Dibolehkan"
      />
      <div class="col-12 p-0 mt-2">
        <v-btn color="primary" @click="addAllowedEmail" type="button" id="allowedEmailBtn">
          Tambah Email
        </v-btn>
      </div>
    </div>
  </div>
</template>

<script>
const AllowedEmailField = () =>
  import('@/components/promo-management/promo/form/allowed-emails/allowed-email-field');
import { mapState } from 'vuex';

export default {
  name: 'nearby-places-section',
  components: {
    AllowedEmailField,
  },
  computed: {
    ...mapState({
      allowedEmails: (state) => state.promo.form.allowedEmails,
    }),
  },
  methods: {
    addAllowedEmail() {
      this.$store.commit('promo/form/ADD_ALLOWED_EMAILS');
    },
  },
};
</script>

<style scoped></style>
